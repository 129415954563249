import * as React from 'react';
import {appScale} from "../../../app-scale";
const DEFAULT_ICON_FILL_COLOR = '#937207';
const side = {desktop: '32', tablet: '32', mobile: '26'};
const DEFAULT_WIDTH = side[appScale()];
const DEFAULT_HEIGHT = side[appScale()];

export const Decor1 = ({fillColor = DEFAULT_ICON_FILL_COLOR,
                       width = DEFAULT_WIDTH,
                       height = DEFAULT_HEIGHT}) => {
    return (
        <svg className='vonmi-svg-icon' version="1.0" xmlns="http://www.w3.org/2000/svg"
             width={width} height={height} viewBox="0 0 800.000000 798.000000"
             preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,798.000000) scale(0.100000,-0.100000)"
               fill={fillColor || DEFAULT_ICON_FILL_COLOR} stroke="none">
                <path d="M1818 7964 c-134 -32 -261 -133 -316 -251 -89 -193 60 -520 290 -637
76 -38 77 -38 175 -34 142 6 220 44 264 130 37 72 18 170 -46 246 -61 72 -228
164 -301 165 -149 2 -197 -207 -63 -275 70 -37 142 -11 180 64 l21 42 49 -51
c37 -39 49 -59 49 -83 0 -93 -173 -144 -295 -86 -63 29 -138 110 -173 184 -38
81 -42 189 -12 261 58 135 239 206 436 172 87 -15 324 -106 469 -179 147 -74
229 -156 382 -382 197 -292 399 -507 539 -577 68 -33 203 -63 289 -63 92 0
248 75 300 144 37 49 80 191 79 261 -2 117 -55 215 -155 285 -183 128 -333
131 -400 6 -34 -62 -42 -112 -28 -175 13 -61 55 -111 93 -111 35 0 43 25 21
66 -22 42 -15 108 15 138 67 68 284 -45 306 -159 16 -86 -40 -211 -117 -262
-35 -23 -55 -29 -120 -32 -90 -4 -218 25 -283 63 -67 39 -183 163 -262 281
-78 116 -245 333 -339 441 -122 138 -318 280 -477 344 -185 74 -422 100 -570
64z"/>
                <path d="M4392 7945 c-186 -34 -278 -131 -303 -317 -28 -203 50 -413 219 -590
259 -271 614 -344 1052 -217 155 45 231 93 479 301 194 163 396 282 551 324
102 28 288 24 413 -9 122 -32 196 -69 317 -156 150 -107 270 -279 325 -466 39
-131 46 -317 16 -435 -40 -158 -155 -357 -318 -550 -200 -237 -251 -313 -292
-437 -78 -232 -98 -478 -53 -657 24 -97 82 -227 133 -303 78 -114 209 -229
334 -290 118 -59 159 -68 305 -68 125 0 139 2 190 26 153 72 212 197 212 449
0 144 -6 163 -95 283 -80 110 -138 142 -272 153 -96 8 -146 -7 -206 -63 -90
-84 -116 -187 -71 -286 40 -90 165 -197 229 -197 11 0 31 8 43 18 21 17 22 22
11 55 -7 19 -36 63 -66 96 -59 67 -71 101 -55 160 14 51 42 71 103 71 59 0 95
-18 139 -69 74 -87 105 -199 89 -317 -21 -146 -105 -223 -252 -232 -250 -15
-522 203 -600 481 -20 71 -23 104 -23 237 1 139 4 167 32 273 52 199 107 292
321 542 221 258 310 450 330 715 20 263 -39 497 -174 685 -61 85 -227 248
-307 301 -290 193 -702 217 -1036 59 -123 -59 -201 -112 -352 -241 -229 -195
-343 -262 -525 -310 -131 -35 -360 -44 -475 -20 -287 59 -522 323 -523 586 -1
100 22 159 81 211 59 52 96 63 212 64 94 0 111 -3 160 -27 137 -68 200 -171
155 -257 -33 -64 -141 -68 -203 -8 -107 103 -182 115 -182 30 0 -107 168 -253
290 -253 86 1 188 68 232 154 15 30 20 58 19 115 -1 140 -40 218 -150 302
-112 84 -139 94 -271 96 -64 1 -147 -4 -188 -12z"/>
                <path d="M3400 7718 c-74 -26 -222 -108 -272 -151 -21 -19 -26 -26 -13 -22 81
29 136 37 220 32 105 -5 179 -31 244 -82 51 -41 83 -44 116 -11 59 60 31 166
-59 221 -66 40 -146 44 -236 13z"/>
                <path d="M320 7616 c-95 -21 -169 -68 -232 -149 l-32 -42 44 40 c64 58 108 82
179 101 97 24 143 14 361 -81 191 -84 301 -116 421 -122 103 -6 171 5 250 38
61 27 74 48 59 94 -15 46 -37 57 -72 39 -117 -61 -345 -56 -573 12 -50 15
-124 37 -165 49 -99 29 -173 36 -240 21z"/>
                <path d="M2464 7580 c-27 -11 -72 -65 -80 -96 -11 -43 14 -80 87 -130 75 -51
119 -104 259 -314 151 -227 280 -374 401 -457 302 -207 669 -201 890 16 73 72
132 176 144 256 5 32 2 27 -18 -26 -132 -361 -590 -451 -944 -185 -125 94
-208 200 -353 451 -51 88 -120 203 -155 255 -34 52 -78 121 -99 153 -25 40
-48 63 -71 73 -19 8 -36 14 -37 13 -2 0 -12 -4 -24 -9z"/>
                <path d="M3253 7406 c-26 -22 -28 -30 -28 -93 0 -92 34 -162 116 -238 52 -47
199 -155 213 -155 2 0 -9 14 -26 32 -62 67 -98 180 -98 316 0 79 -15 120 -52
146 -34 24 -92 20 -125 -8z"/>
                <path d="M325 7410 c-105 -12 -188 -68 -256 -173 -84 -129 -91 -381 -13 -411
47 -18 84 51 84 156 0 102 17 156 65 204 25 24 59 47 81 53 123 34 284 -16
315 -97 8 -22 0 -46 -55 -158 -72 -145 -101 -244 -92 -314 11 -84 37 -141 85
-190 62 -65 130 -85 253 -76 149 10 265 62 320 143 42 60 49 89 47 198 -2 90
1 107 19 137 63 102 269 160 378 107 24 -11 75 -49 113 -84 112 -99 196 -130
344 -123 102 4 172 35 250 113 97 94 147 241 109 313 -23 40 -51 28 -78 -35
-72 -168 -109 -219 -186 -257 -62 -31 -169 -36 -222 -11 -18 9 -88 61 -157
116 -178 145 -237 170 -349 149 -125 -23 -246 -112 -319 -235 -33 -54 -35 -65
-42 -173 -7 -109 -9 -118 -33 -139 -62 -53 -257 -71 -320 -29 -61 39 -104 152
-85 221 5 17 37 70 70 116 132 180 141 260 43 369 -76 84 -221 127 -369 110z"/>
                <path d="M1143 7249 c-17 -6 -23 -16 -23 -38 0 -41 32 -59 66 -36 26 17 29 27
14 54 -13 24 -30 30 -57 20z"/>
                <path d="M949 7199 c-15 -15 -19 -28 -14 -47 13 -56 70 -69 104 -25 49 61 -34
128 -90 72z"/>
                <path d="M6770 7204 c-128 -23 -236 -51 -299 -76 -181 -71 -344 -181 -502
-338 -277 -276 -439 -593 -439 -861 0 -205 84 -337 254 -396 81 -29 255 -24
371 11 119 35 308 130 425 213 110 80 305 269 377 367 153 208 231 401 264
649 26 205 -26 333 -165 404 -44 23 -65 27 -156 29 -58 1 -116 0 -130 -2z
m210 -54 c148 -41 217 -176 191 -372 -34 -261 -118 -449 -289 -648 -263 -305
-569 -482 -837 -484 -169 -1 -279 59 -345 189 -35 67 -35 69 -35 194 1 115 4
134 34 221 53 156 139 299 262 438 139 157 355 323 497 381 63 26 228 72 302
84 51 8 186 7 220 -3z"/>
                <path d="M6737 7126 c-217 -39 -384 -118 -568 -272 -86 -72 -201 -201 -272
-307 -230 -341 -238 -681 -20 -799 307 -168 917 183 1161 668 101 200 140 471
84 581 -26 50 -77 98 -126 118 -54 23 -165 27 -259 11z m223 -48 c54 -16 96
-53 121 -105 34 -68 32 -190 -5 -331 -48 -187 -126 -320 -285 -485 -156 -162
-328 -272 -495 -318 -106 -28 -239 -24 -306 10 -103 53 -151 131 -158 257 -11
203 101 436 308 644 169 170 312 260 483 305 167 45 249 50 337 23z"/>
                <path d="M3765 7135 c-50 -49 -15 -135 55 -135 41 0 80 39 80 80 0 41 -39 80
-80 80 -19 0 -40 -9 -55 -25z"/>
                <path d="M812 7064 c-32 -22 -43 -57 -31 -94 20 -55 91 -69 134 -25 68 67 -25
174 -103 119z"/>
                <path d="M1320 6913 c-64 -57 -43 -159 38 -183 113 -34 191 108 101 184 -43
36 -98 35 -139 -1z"/>
                <path d="M4358 6802 c-8 -114 -68 -223 -153 -282 -53 -37 -58 -64 -19 -104 54
-53 153 -24 205 61 21 33 24 50 23 128 -1 75 -32 246 -48 262 -2 2 -5 -27 -8
-65z m34 -182 c0 -47 -5 -98 -12 -115 -34 -89 -142 -125 -172 -59 -11 23 -9
29 12 46 85 66 130 135 146 223 7 37 8 38 16 15 5 -14 9 -63 10 -110z"/>
                <path d="M711 6834 c-36 -30 -49 -80 -29 -120 40 -81 147 -89 191 -14 28 46
22 87 -17 126 -44 44 -99 47 -145 8z"/>
                <path d="M2470 6809 c-214 -23 -398 -112 -499 -239 -96 -121 -123 -318 -62
-455 37 -84 175 -190 306 -235 189 -66 498 -86 740 -49 94 15 225 62 308 111
37 22 106 75 153 119 92 88 111 97 300 159 165 54 277 64 341 31 79 -40 97
-103 41 -143 -28 -20 -48 -23 -135 -26 -84 -3 -104 -7 -118 -23 -33 -36 10
-76 92 -86 65 -7 179 12 228 40 61 34 105 99 105 153 0 79 -34 120 -150 180
-50 26 -64 29 -155 28 -145 -1 -239 -30 -665 -207 -372 -155 -488 -187 -667
-187 -336 1 -537 129 -535 342 0 87 40 176 113 253 153 161 463 202 620 82 53
-40 109 -121 109 -156 0 -72 -167 -138 -325 -129 l-60 3 37 18 c63 29 82 78
37 92 -29 10 -116 -23 -139 -52 -26 -33 -25 -62 3 -103 37 -57 80 -75 172 -75
151 1 295 68 363 169 38 58 40 93 8 161 -30 65 -99 126 -200 176 -115 56 -194
67 -366 48z"/>
                <path d="M1570 6753 c-75 -27 -81 -123 -10 -153 79 -33 150 70 88 127 -26 24
-56 34 -78 26z"/>
                <path d="M176 6675 c-47 -50 -63 -196 -31 -285 63 -177 275 -332 455 -332 74
0 212 40 350 102 100 45 233 75 300 67 113 -14 296 -106 387 -196 97 -95 115
-181 61 -290 -22 -44 -38 -56 -118 -90 -24 -10 -24 -10 5 -5 124 24 160 53
195 158 31 93 25 191 -15 271 -87 171 -365 317 -576 302 -73 -5 -213 -48 -516
-159 -57 -21 -66 -22 -135 -10 -91 15 -205 70 -248 119 -74 84 -132 265 -102
319 6 11 35 26 64 36 l53 16 -53 1 c-44 1 -57 -3 -76 -24z"/>
                <path d="M4683 6667 c-50 -55 -63 -102 -73 -276 -8 -136 -10 -145 -33 -165
-38 -32 -49 -61 -54 -145 -4 -56 0 -99 15 -161 11 -47 23 -116 26 -155 4 -38
12 -84 17 -102 13 -41 77 -100 187 -170 145 -93 154 -107 104 -161 -37 -40
-74 -49 -107 -28 l-26 17 30 36 c17 20 28 40 25 45 -8 13 -48 9 -71 -7 -64
-45 -14 -155 70 -155 13 0 58 30 107 71 120 100 173 129 236 129 69 0 156 -44
229 -118 74 -73 109 -147 110 -227 0 -70 -15 -98 -113 -214 -94 -111 -96 -114
-82 -156 14 -44 44 -65 91 -65 44 0 69 25 69 68 0 39 -4 39 -47 6 -27 -21 -37
-23 -48 -14 -25 21 -18 66 16 105 54 61 89 45 154 -70 84 -149 173 -225 263
-225 24 0 90 -10 145 -21 178 -37 284 -30 337 23 31 31 34 31 158 39 171 11
277 42 302 89 14 26 11 26 -113 -6 -123 -32 -228 -36 -308 -12 -73 23 -183 97
-287 192 -136 125 -200 155 -377 176 -55 7 -101 13 -102 14 -1 1 -7 41 -12 88
-7 62 -18 103 -40 146 -70 139 -220 232 -373 232 -55 0 -53 -3 -63 119 -8 92
-35 182 -74 242 -16 26 -66 86 -111 135 -139 152 -188 243 -197 369 -5 67 2
109 41 267 16 64 9 79 -21 45z m-19 -515 c19 -38 46 -83 60 -99 14 -17 26 -38
26 -46 0 -30 -134 -107 -154 -87 -7 7 -11 56 -11 134 0 108 2 127 19 145 11
12 21 21 22 21 2 0 19 -31 38 -68z m201 -254 c42 -36 84 -114 114 -212 22 -72
28 -146 11 -146 -5 0 -38 34 -72 76 -47 57 -89 94 -165 146 -57 37 -103 72
-103 76 0 12 51 58 85 75 49 26 90 21 130 -15z m-125 -197 c94 -71 180 -164
180 -195 0 -14 -5 -26 -10 -26 -23 0 -86 32 -161 82 -65 43 -84 61 -104 102
-14 27 -25 62 -25 77 0 26 2 27 28 18 15 -5 56 -31 92 -58z m1021 -771 c58
-21 160 -82 192 -114 33 -34 15 -108 -41 -168 -14 -16 -32 -28 -39 -28 -7 0
-41 44 -76 98 -49 75 -82 112 -145 165 -45 37 -82 70 -82 74 0 13 137 -6 191
-27z m-97 -135 c63 -65 123 -148 142 -197 14 -38 -94 -11 -145 35 -29 26 -112
151 -130 195 -36 87 31 70 133 -33z m452 -125 c28 -20 70 -44 93 -54 22 -11
41 -23 41 -27 0 -5 -12 -17 -27 -26 -22 -15 -49 -18 -140 -18 -136 0 -146 6
-116 68 28 58 72 109 86 100 7 -4 35 -23 63 -43z"/>
                <path d="M1278 6669 c139 -23 216 -112 210 -244 -3 -69 11 -98 46 -93 51 7 58
114 11 193 -40 70 -72 102 -127 129 -42 21 -65 26 -122 25 l-71 -1 53 -9z"/>
                <path d="M1650 6530 c-24 -24 -25 -48 -4 -78 20 -29 79 -31 94 -3 17 32 12 69
-12 85 -30 21 -54 20 -78 -4z"/>
                <path d="M2276 6479 c-71 -56 -84 -158 -29 -227 69 -86 195 -132 361 -132 216
0 396 85 479 228 35 59 25 61 -17 4 -19 -26 -63 -66 -98 -89 -195 -131 -517
-120 -602 20 -30 48 -26 93 10 129 37 36 37 41 4 67 -34 27 -74 27 -108 0z"/>
                <path d="M1646 6344 c-19 -18 -20 -28 -6 -55 20 -37 80 -18 80 26 0 42 -44 59
-74 29z"/>
                <path d="M5097 5346 c-129 -47 -183 -180 -121 -303 28 -58 82 -92 159 -101 75
-8 113 5 164 56 93 93 92 227 -3 310 -57 50 -128 63 -199 38z m164 -62 c43
-21 73 -92 65 -148 -14 -85 -81 -146 -160 -146 -96 0 -166 70 -166 165 0 77
42 126 125 148 31 9 102 -1 136 -19z"/>
                <path d="M6529 4401 c-44 -14 -86 -51 -110 -96 -19 -36 -21 -49 -13 -81 11
-45 52 -79 86 -71 13 4 32 22 42 42 43 79 163 144 285 154 l76 6 -90 24 c-97
25 -234 36 -276 22z m226 -32 c18 -7 17 -8 -7 -8 -15 -1 -53 -11 -85 -22 -45
-16 -72 -36 -120 -86 -62 -64 -63 -65 -87 -50 -34 23 -40 50 -20 92 20 42 58
72 110 84 41 10 175 4 209 -10z"/>
                <path d="M6098 4232 c-80 -58 -108 -127 -108 -268 0 -78 16 -126 45 -137 44
-17 68 31 59 117 -15 146 69 227 145 141 64 -74 63 -166 -5 -377 -56 -174 -77
-215 -153 -293 -85 -87 -141 -174 -181 -276 -53 -137 -61 -189 -67 -404 -8
-301 27 -495 114 -644 90 -151 177 -212 317 -219 157 -8 262 31 367 138 121
123 184 294 196 531 5 111 3 134 -15 195 -54 176 -181 313 -291 314 -68 0
-151 -72 -206 -180 -48 -94 -61 -260 -26 -329 34 -65 102 -96 147 -66 49 32
81 138 50 164 -22 19 -54 -4 -76 -52 -15 -36 -19 -39 -26 -23 -11 28 12 207
35 267 26 68 56 99 96 99 61 0 170 -103 200 -189 26 -71 31 -213 11 -290 -38
-148 -118 -257 -238 -322 -58 -32 -70 -34 -153 -34 -80 0 -95 3 -135 27 -97
57 -152 143 -184 286 -55 244 -25 424 130 800 203 489 244 617 244 757 0 74
-3 89 -35 153 -20 38 -53 86 -75 106 -36 32 -46 36 -93 36 -40 -1 -60 -7 -89
-28z"/>
                <path d="M6815 4142 c-168 -58 -301 -207 -351 -392 -23 -86 -23 -242 0 -335
24 -100 104 -257 179 -351 74 -92 165 -173 309 -271 333 -228 354 -245 440
-362 43 -58 76 -69 130 -45 41 18 78 64 78 96 0 32 -23 67 -59 91 -158 104
-331 213 -411 257 -315 176 -464 313 -561 515 -64 134 -83 273 -54 407 39 185
163 329 332 388 32 10 49 19 38 19 -11 0 -43 -7 -70 -17z"/>
                <path d="M6920 4109 c-145 -40 -213 -98 -266 -227 -42 -102 -28 -306 29 -419
76 -149 289 -348 586 -548 243 -164 302 -225 395 -412 52 -106 141 -340 157
-413 6 -29 9 -96 7 -149 -3 -78 -9 -107 -32 -158 -34 -74 -93 -132 -159 -158
-140 -53 -350 38 -429 187 -45 85 -21 254 41 288 37 19 79 11 116 -21 60 -53
61 -63 11 -93 -79 -46 -95 -130 -38 -198 50 -59 121 -73 186 -35 51 29 79 79
73 132 -11 104 -139 290 -231 334 -58 29 -138 28 -191 -2 -119 -68 -163 -290
-85 -436 45 -84 119 -163 203 -215 98 -60 183 -88 290 -94 83 -4 91 -3 153 28
122 60 217 183 245 318 17 85 6 327 -20 423 -72 264 -259 524 -524 726 -56 42
-124 96 -152 119 -27 22 -96 71 -151 107 -113 72 -260 210 -292 273 -33 65
-55 163 -56 254 -1 75 2 90 26 131 65 110 227 162 315 102 112 -76 165 -214
109 -280 -20 -23 -32 -28 -73 -27 -26 0 -63 6 -81 14 -29 13 -34 13 -42 0 -11
-18 -4 -48 19 -74 26 -30 75 -46 143 -46 76 0 137 27 176 78 24 31 27 44 27
112 0 68 -4 84 -38 154 -97 196 -259 277 -447 225z"/>
                <path d="M7048 3876 c-26 -19 -39 -62 -28 -90 32 -85 150 -61 150 29 0 59 -75
97 -122 61z"/>
                <path d="M7526 3705 c-33 -18 -55 -43 -56 -65 0 -9 18 -40 40 -69 41 -54 73
-142 85 -232 7 -58 -9 -163 -35 -223 -10 -25 -17 -46 -15 -46 15 0 146 217
175 291 50 126 44 214 -21 293 -52 62 -116 81 -173 51z"/>
                <path d="M6955 3510 c49 -78 111 -154 173 -211 47 -44 72 -59 122 -73 84 -24
140 -16 175 25 29 35 31 56 11 104 -20 48 -55 61 -176 68 -138 8 -222 34 -285
89 l-48 42 28 -44z"/>
                <path d="M6349 3072 c-206 -132 -284 -502 -156 -748 25 -48 85 -103 127 -115
81 -24 190 40 190 113 0 35 -23 78 -42 78 -6 0 -26 -14 -46 -31 -30 -26 -41
-30 -73 -26 -83 11 -138 89 -160 225 -28 171 51 402 167 488 55 39 60 44 48
44 -6 0 -30 -13 -55 -28z"/>
                <path d="M7083 2365 c-103 -32 -213 -130 -261 -232 -23 -50 -27 -71 -27 -153
0 -138 25 -205 116 -307 91 -102 109 -140 109 -231 0 -81 -24 -157 -70 -221
-45 -61 -72 -71 -200 -71 -91 0 -118 -4 -150 -20 -118 -60 -182 -195 -184
-385 -1 -79 3 -97 27 -147 47 -97 141 -148 271 -148 88 0 140 17 294 94 88 44
120 56 142 51 97 -21 148 -233 86 -354 -20 -37 -67 -77 -108 -90 -18 -6 -79
-14 -135 -19 -114 -10 -153 -27 -153 -67 0 -45 50 -65 160 -65 156 0 249 38
340 136 57 62 81 121 87 213 14 219 -104 402 -259 401 -56 -1 -114 -28 -213
-99 -104 -76 -115 -81 -169 -81 -55 0 -122 28 -159 67 -55 59 -60 214 -9 312
26 50 53 61 152 61 94 1 140 14 203 57 103 71 182 179 206 284 29 124 1 197
-143 369 -108 131 -128 165 -134 235 -12 138 73 247 241 311 108 41 132 80 65
103 -40 14 -72 13 -125 -4z"/>
                <path d="M5875 1790 c-55 -11 -135 -47 -163 -73 -12 -11 -29 -43 -37 -71 -22
-74 -18 -88 9 -29 29 62 79 92 167 100 46 5 66 1 103 -17 128 -62 286 -329
286 -484 0 -80 -24 -182 -64 -270 -56 -123 -106 -288 -106 -351 0 -67 30 -160
75 -235 46 -76 151 -175 222 -208 50 -23 70 -27 153 -27 78 0 102 4 136 22 49
26 69 66 58 115 l-7 33 -13 -50 c-7 -27 -21 -56 -31 -63 -30 -23 -124 -9 -211
33 -122 57 -178 124 -216 257 -27 91 -25 143 11 238 127 343 143 398 143 502
0 158 -75 342 -185 454 -66 68 -132 106 -213 123 -59 12 -60 12 -117 1z"/>
                <path d="M6475 1738 c-26 -15 -35 -28 -35 -54 0 -51 64 -80 99 -45 50 50 -4
133 -64 99z"/>
                <path d="M6301 1704 c-28 -20 -27 -50 3 -69 36 -24 68 -5 64 37 -4 38 -36 53
-67 32z"/>
                <path d="M6632 1640 c-33 -31 -30 -92 4 -119 43 -34 102 -21 123 26 16 35 14
57 -10 87 -26 33 -84 36 -117 6z"/>
                <path d="M6360 1550 c-23 -23 -24 -31 -7 -52 9 -12 34 -17 99 -20 77 -3 91 -6
130 -33 56 -39 98 -119 99 -189 1 -41 3 -46 9 -26 35 110 -39 253 -163 315
-65 32 -138 34 -167 5z"/>
                <path d="M6791 1476 c-32 -18 -50 -53 -51 -97 0 -45 29 -84 75 -99 56 -19 106
6 131 66 38 90 -67 179 -155 130z"/>
                <path d="M7447 1358 c-24 -18 -64 -139 -73 -215 -13 -127 30 -302 126 -513 70
-154 85 -204 85 -290 0 -93 -30 -164 -102 -242 l-48 -52 43 33 c128 100 183
239 152 393 -6 35 -29 117 -50 183 -87 274 -100 477 -39 614 24 54 18 76 -23
90 -40 14 -50 14 -71 -1z"/>
                <path d="M7192 1188 c-28 -28 -7 -78 33 -78 42 0 59 44 29 74 -18 19 -45 21
-62 4z"/>
                <path d="M7135 1025 c-29 -28 -31 -42 -9 -73 30 -44 114 -18 114 36 0 18 -46
62 -65 62 -9 0 -27 -11 -40 -25z"/>
                <path d="M6955 905 c-51 -50 -15 -135 58 -135 45 0 77 34 77 82 0 70 -86 103
-135 53z"/>
                <path d="M6694 846 c-40 -40 -45 -83 -14 -133 66 -109 245 -8 186 105 -22 42
-53 62 -99 62 -31 0 -46 -7 -73 -34z"/>
            </g>
        </svg>
    );
};
