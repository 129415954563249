import { BackButton } from '../BackButton/BackButton';
import styled from 'styled-components';

export function Header({children, onAppClose}) {
    return (
        <Wrapper>
            <BackButton onClick={onAppClose}/>
            <HeaderTitle>{children}</HeaderTitle>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: left;
    row-gap: 8px;
`;

const HeaderTitle = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 22px;
    color: rgb(147, 114, 7);
`;
