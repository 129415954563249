import { useState, useRef } from 'react';
import styled from 'styled-components';
import {WithBorder} from '../ui/withBorder';
import {appScale} from "../../app-scale";
import { Header } from '../Header/Header';
import {Calendar} from "react-calendar";
import { IMaskMixin } from 'react-imask';
import './DatePicker.css';
import { MainButton } from '../../MainButton/MainButton';

export function DatePicker(props) {
    const [date, setDate] = useState(new Date());

    const formatDate = (d) => {
        const yyyy = d.getFullYear();
        let mm = d.getMonth() + 1;
        let dd = d.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return dd + '/' + mm + '/' + yyyy;
    };

    const ref = useRef(null);
    const inputRef = useRef(null);

    return (
    <Wrapper background_color={props.background_color}>
        <WithBorder type={props.cart_border}>
            <Container>
                <Header onAppClose={() => {
                    console.log('test')
                }}>
                    Выберите дату
                </Header>
            </Container>
            <Container>
                <Calendar
                    minDetail='month'
                    minDate={new Date()}
                    onChange={setDate.bind(this)}
                    value={date}
                    className="calendar-widget"
                />

                <MaskedStyledInput
                    mask={Date}
                    unmask={true}
                    ref={ref}
                    value={formatDate(date)}
                    inputRef={inputRef}  // access to nested input
                    onComplete={(val) => {
                        const dateParts = val.split(".");
                        const newDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);

                        if (newDate instanceof Date && !isNaN(newDate)) {
                            setDate(newDate);
                        }
                    }}
                    // input props also available
                    placeholder='Введите дату'
                />
            </Container>
        </WithBorder>

        <MainButton onClick={() => {
            window.Telegram.WebApp.sendData(
                window.Telegram.WebApp.sendData([{
                    date: date.toISOString().slice(0, 10)
                }])
            );

            return window.Telegram.WebApp.close();
        }}>
            Применить
        </MainButton>
    </Wrapper>
    )
};

const MaskedStyledInput = IMaskMixin(({inputRef, ...props}) => (
    <StyledInput
        {...props}
        ref={inputRef}
    />
));

const width = {desktop: '100%', tablet: '100%', mobile: '100%'};
const Wrapper = styled.div`
      display: flex;
    min-height: auto;
    width: ${width[appScale()]};
    background: ${props => props.background_color || 'white'};
    margin: 4px;
    position: relative;
    min-height: 90vh;
  `;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;
    position: relative;
  `;

const StyledInput = styled.input`
    background: none;
    border-color: currentcolor currentcolor rgb(204, 197, 175);
    border-style: none none solid;
    border-width: 0px 0px 1px;
    border-image: none 100% / 1 / 0 stretch;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 4px;
    margin-bottom: 16px;
    outline: none;
    width: 100%;
    border-radius: 0px;
    -moz-box-flex: 1;
    flex-grow: 1;
`;
