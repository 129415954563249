import React, {Fragment} from 'react'
import Decor from "../decor";
import styled from 'styled-components'
import {appScale} from "../../../app-scale";

export function WithBorder({children, decor_color, type = 0}) {
    return (
        <Wrapper type={type}>
          {type == 0 && (
            <Fragment>
              <DecorTopRight><Decor color={decor_color}/></DecorTopRight>
              <DecorTopLeft><Decor color={decor_color} /></DecorTopLeft>
              <DecorBottomRight><Decor color={decor_color} /></DecorBottomRight>
              <DecorBottomLeft><Decor color={decor_color} /></DecorBottomLeft>

              <BorderTop decor_color={decor_color} />
              <BorderBottom decor_color={decor_color} />
              <BorderLeft decor_color={decor_color}/>
              <BorderRight decor_color={decor_color}/>
            </Fragment>
          )}

          {type == 1 && <Border1 />}
          {type == 2 && <Border2 />}
            {children}
        </Wrapper>
    )
};

const paddings = {'type-1' : '5px', 'type0': '20px 15px 18px', 'type1': '25px 25px 20px', 'type2': '25px 25px 20px'};
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    margin: 6px;
    padding: ${props => paddings['type'+props.type] || '20px 15px 18px'};
    position: relative;
`;

const DecorTopRight = styled.div`
    position: absolute;
    right: 0;
    top: 0;
`;

  const DecorTopLeft = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    transform: rotate(270deg);
`;


const DecorBottomRight = styled.div`
   position: absolute;
    right: 0;
    bottom: 0;
    transform: rotate(90deg);
`;

const DecorBottomLeft = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    transform: rotate(180deg);
`;

const widthCalc = {desktop: '72px', tablet: '72px', mobile: '64px'};
const widthIdent = {desktop: '36px', tablet: '36px', mobile: '32px'};
const BorderTop = styled.div`
    height: 1px;
    width: calc(100% - ${widthCalc[appScale()]});
    background: ${props => props.decor_color || '#937207'};
    position: absolute;
    top: 5px;
    left: ${widthIdent[appScale()]};
`;

const BorderBottom = styled.div`
    height: 1px;
    width: calc(100% - ${widthCalc[appScale()]});
    background: ${props => props.decor_color || '#937207'};
    position: absolute;
    bottom: 3px;
    left: ${widthIdent[appScale()]};
`;

const leftIndent = {desktop: '3px', tablet: '3px', mobile: '1px'};
const BorderLeft = styled.div`
    width: 1px;
    height: calc(100% - ${widthCalc[appScale()]});
    background: ${props => props.decor_color || '#937207'};
    position: absolute;
    left: ${leftIndent[appScale()]};
    top: ${widthIdent[appScale()]};
`;

const rightIndent = {desktop: '5px', tablet: '5px', mobile: '2px'};
const BorderRight = styled.div`
    width: 1px;
    height: calc(100% - ${widthCalc[appScale()]});
    background: ${props => props.decor_color || '#937207'};
    position: absolute;
    right: ${rightIndent[appScale()]};
    top: ${widthIdent[appScale()]};
`;

const Border1 = styled.div`
position: absolute;
width:100%;
height: 100%;
border: 15px solid #40c4c8;
    -moz-border-image: url(https://cdn.vonmi.org/widget/icons/border1.png) 28 / 28px / 0 round ;
    -webkit-border-image: url(https://cdn.vonmi.org/widget/icons/border1.png) 28px / 47px / 0 round ;
    -o-border-image: url(https://cdn.vonmi.org/widget/icons/border1.png) 28 / 28px / 0 round ;
    border-image: url(https://cdn.vonmi.org/widget/icons/border1.png) 28 / 28px / 0 round ;
left: 0;
top: 0;
`;

const Border2 = styled.div`
position: absolute;
width:100%;
height: 100%;
border: 15px solid #fff;
    -moz-border-image: url(https://cdn.vonmi.org/widget/icons/border2.png) 28 / 28px / 0 round ;
    -webkit-border-image: url(https://cdn.vonmi.org/widget/icons/border2.png) 28px / 47px / 0 round ;
    -o-border-image: url(https://cdn.vonmi.org/widget/icons/border2.png) 28 / 28px / 0 round ;
    border-image: url(https://cdn.vonmi.org/widget/icons/border2.png) 28 / 28px / 0 round ;
left: 0;
top: 0;
`;