import styled from 'styled-components';

export function BackButton({onClick}) {
    return (
        <Button onClick={(e) => {
            e.preventDefault();
            onClick();
            return window.Telegram.WebApp.close();
        }}>←</Button>
    )
}

const Button = styled.button`
    display: inline-block;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: #937207;
`;
