import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

let tg = window.Telegram.WebApp;
let cartData;

try {
  cartData = JSON.parse(
    decodeURI(
      new URLSearchParams(window.location.search).get('cart')
    )
  );
} catch (e) { console.log(e) };

window.Telegram = window.Telegram || {};
window.Telegram.WebApp = window.Telegram.WebApp || {};
window.Telegram.WebApp.cart = cartData;
tg.expand();
tg.ready();

const root = ReactDOM.createRoot(document.getElementById('vonmi-widget'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
