import { useState } from 'react';
import {WithBorder} from '../ui/withBorder';
import {DeleteIcon} from "../ui/icons/deteleIcon";
import {PencilIcon} from "../ui/icons/pencil";
import { MainButton } from '../../MainButton/MainButton';
import {appScale} from "../../app-scale";
import styled from 'styled-components';
import { CartItem } from './CartItem';
import { Header } from '../Header/Header';
import { useEffect } from 'react';

export function Cart(props) {
    const [cart, setCart] = useState(window.Telegram.WebApp.cart);
    const saveCart = () => {
        window.Telegram.WebApp.sendData([{
            event: 'createOrder',
            data: cart
        }]);
    }

    useEffect(() => {
        console.log('effect used');
        window.Telegram.WebApp.BackButton.hide();
        window.Telegram.WebApp.enableClosingConfirmation();
        // window.Telegram.WebApp.onEvent('backButtonClicked', saveCart);
        // return window.Telegram.WebApp.offEvent('backButtonClicked', saveCart);
    });

    return (
    <Wrapper background_color={props.background_color}>
        <WithBorder type={props.cart_border}>
            <Container>
                <CartHeader>
                    <CartHeaderTop>
                        <Header onAppClose={() => {
                            window.Telegram.WebApp.sendData([{
                                event: 'cartUpdated',
                                data: cart,
                            }]);
                            return window.Telegram.WebApp.close();
                        }}>
                            Корзина
                        </Header>
                    </CartHeaderTop>
                    <CartSub>
                        <ClearButton onClick={(e) => {
                            e.preventDefault();
                            window.Telegram.WebApp.showConfirm(
                                'Вы уверены, что хотите очистить корзину?',
                                () => setCart([])
                            );
                        }}>
                            <DeleteIcon fillColor='rgb(152, 152, 152)' /> Очистить корзину
                        </ClearButton>
                    </CartSub>
                </CartHeader>
                {!!cart && <CartItems>
                    {!cart?.length &&
                    <div>
                        <EmptyStateIcon src='//cdn.vonmi.org/widget/icons/emptystate.png'/>
                        <EmptyText><EmptyTitle>Корзина пуста</EmptyTitle>Выберите любой раздел, чтобы заказать поминовение или сделать пожертвование.</EmptyText>
                    </div>}
                {cart?.map((item, i) => {
                    // const section = sections.find(s => s.id === item.section);
                    // let sectionName = sections.name;
                    let sectionName = item.name;
                    let icon = '';
                    let isLink = true;

                    // if (section.type == 'candle') {
                    //     sectionName = section.names.find(name => name.id == item.data.sv).name;
                    //     icon = '//cdn.vonmi.org/widget/icons/candle.gif';
                    //     isLink = false;
                    // }

                    return (
                      <CartItem
                        key={i}
                        name={sectionName}
                        cost={Number(item.cost)}
                        onDelete={e => {
                            e.preventDefault();
                            setCart(
                                cart?.filter(
                                    (el) => el !== item
                                )
                            );
                        }}
                        icon={icon}
                        isLink={isLink}
                      />)}
                      )
                }
                </CartItems>}
            </Container>

            <Summary>
                <SummaryTitle>Итого</SummaryTitle>
                {/* <SummaryCost>{cart.reduce((acc, item) => acc + Number(item.cost), 0)} ₽</SummaryCost> */}
                <SummaryCost>
                    {cart?.reduce((acc, obj) => {
                        return acc + Number(obj.cost)
                    }, 0)} ₽
                    {/* <EditCostButton>
                        <PencilIcon fillColor='#937207' />
                    </EditCostButton> */}
                </SummaryCost>
            </Summary>
        </WithBorder>

        <MainButton onClick={saveCart}>
            Перейти к оформлению
        </MainButton>
    </Wrapper>
    )
};

const width = {desktop: '100%', tablet: '100%', mobile: '100%'};
const Wrapper = styled.div`
      display: flex;
    min-height: auto;
    width: ${width[appScale()]};
    background: ${props => props.background_color || 'white'};
    margin: 4px;
    position: relative;
    min-height: 90vh;
  `;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;
    position: relative;
  `;

const CartHeaderTop = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: left;
`;

const CartSub = styled.div`
    width: 100%;
    display: flex;
    align-items: right;
`;

const CartHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;
`;

const CartItems = styled.div`
    margin-top: 16px;
`;

const EmptyStateIcon = styled.img`
    width: 100px;
    position: absolute;
    bottom: 20px;
    opacity: .4;
    transform: scale(-1, 1);
    right: 30px;
`;

const EmptyText = styled.div`
    z-index: 1;
    position: relative;
    color: #848484;
`;

const EmptyTitle = styled.div`
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;
`;

const ClearButton = styled.button`
    display: inline-block;
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
    color: rgb(152, 152, 152);

    svg {
        width: 12px;
        height: 12px;
    }
`;

const Summary = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    padding: 0 20px;
`;

const SummaryTitle = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #848484;
    font-family: 'Cormorant Infant';
`;

const SummaryCost = styled.div`
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
`;

const EditCostButton = styled.button`
    border: none;
    background: none;
    cursor: pointer;
`;
