import React from 'react'
import styles from 'styled-components'

export function Cost({children, bold, big, hideCurrency}) {
    return (
        <Wrapper bold={bold} big={big}>
            {children == 'NaN' ? 0 : children}&nbsp;{hideCurrency == 0 && 'P'}
        </Wrapper>
    )
};

const Wrapper = styles.span`
    font-size: 20px;
    line-height: 30px;
    font-weight: ${props => props.bold ? 'bold' : 'normal'};
    line-height: 20px;
`;
