import './App.css';
import {addFontStyle} from './Components/utils/addFontStyle';
import { Cart } from './Components/Cart/Cart';
import { DatePicker } from './Components/DatePicker/DatePicker';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  addFontStyle();
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/cart" element={<Cart />} />
        <Route path="/datePicker" element={<DatePicker />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
