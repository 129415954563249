export function addFontStyle(font) {
  const css = `
  @font-face {
    font-family: 'Cormorant Infant';
    src: url('https://cdn.vonmi.org/fonts/cormorantinfant/CormorantInfant-BoldItalic.eot');
    src: local('Cormorant Infant Bold Italic'), local('CormorantInfant-BoldItalic'),
    url('https://cdn.vonmi.org/fonts/cormorantinfant/CormorantInfant-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('https://cdn.vonmi.org/fonts/cormorantinfant/CormorantInfant-BoldItalic.woff') format('woff'),
    url('https://cdn.vonmi.org/fonts/cormorantinfant/CormorantInfant-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: 'Cormorant Infant';
    src: url('https://cdn.vonmi.org/fonts/cormorantinfant/CormorantInfant-Bold.eot');
    src: local('Cormorant Infant Bold'), local('CormorantInfant-Bold'),
    url('https://cdn.vonmi.org/fonts/cormorantinfant/CormorantInfant-Bold.eot?#iefix') format('embedded-opentype'),
    url('https://cdn.vonmi.org/fonts/cormorantinfant/CormorantInfant-Bold.woff') format('woff'),
    url('https://cdn.vonmi.org/fonts/cormorantinfant/CormorantInfant-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Cormorant Infant';
    src: url('https://cdn.vonmi.org/fonts/cormorantinfant/CormorantInfant-SemiBold.eot');
    src: local('Cormorant Infant SemiBold'), local('CormorantInfant-SemiBold'),
    url('https://cdn.vonmi.org/fonts/cormorantinfant/CormorantInfant-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('https://cdn.vonmi.org/fonts/cormorantinfant/CormorantInfant-SemiBold.woff') format('woff'),
    url('https://cdn.vonmi.org/fonts/cormorantinfant/CormorantInfant-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'CyrillicOld';
    font-style: normal;
    font-weight: 700;
    src: local('CyrillicOld'), local('CyrillicOld-Bold'),
    url(https://cdn.vonmi.org/fonts/cyrillicold/cyrillicold.woff) format('woff'),
    url(https://cdn.vonmi.org/fonts/cyrillicold/cyrillicold.ttf) format('truetype');
}
#vonmi-widget div {
  box-sizing: border-box;
} 
::-webkit-input-placeholder { /* Chrome */
  color: #9e9e9e;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #9e9e9e;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #9e9e9e;
  opacity: 1;
}
:-moz-placeholder { /* Firefox 4 - 18 */
  color: #9e9e9e;
  opacity: 1;
}

.react-calendar {
  width: 100%;
  max-width: 350px;
  background: white;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
    font-family: Roboto, sans-serif !important;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  color: #000 !important;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e68c;
}
.react-calendar__navigation__arrow[disabled] {
  color: #cccccc;
}
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
    padding: 5px 0px;
    font-weight: bold;
    border-radius: 5px;
    color: #333 !important;
        font-size: 12px !important;
  background: none;
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000 !important;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575 !important;
}
button.react-calendar__navigation__label {
    color: #9e9e9e !important;
}
.react-calendar__tile:disabled {
  background-color: #fff;
  color: #cacaca !important;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: white !important;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
.react-calendar abbr {
  text-decoration: none !important;
  cursor: pointer !important;
  border-bottom: none !important;
}



#vonmi-widget{line-height: 100% !important;}
#vonmi-widget select, #vonmi-widget input, #vonmi-widget {font-family: ${font || 'Cormorant Infant'};}
`;
  const head = document.head || document.getElementsByTagName('head')[0];
  const fontStyle = document.createElement('style');
  head.appendChild(fontStyle);
  fontStyle.type = 'text/css';
  if (fontStyle.styleSheet){
    // This is required for IE8 and below.
    fontStyle.styleSheet.cssText = css;
  } else {
    fontStyle.appendChild(document.createTextNode(css));
  }
}