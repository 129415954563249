const tabletScale = 848;
const mobileScale = 630;

export function appScale() {
    if (!window.vonmiWidth) {
        window.vonmiWidth = window.innerWidth;
    }
    if (window.vonmiWidth > tabletScale)
        return 'desktop';
    else if (window.vonmiWidth > mobileScale)
        return 'tablet';
    else
        return 'mobile';
}
