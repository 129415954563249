import {Cost} from "../cost/cost";
import styled from 'styled-components';
import {DeleteIcon} from "../ui/icons/deteleIcon";

export function CartItem(props) {
    return (
        <Wrapper itemID={props.itemId}>
          <TitleWrapper>
            <Title hover_color={'#000'}>{props.name}</Title>
            {/* {!!icon && <Icon><img src={icon}/></Icon>} */}
          </TitleWrapper>
            <CartActions>
              <Cost>{props.cost.toFixed(0)}</Cost>
              <Delete onClick={props.onDelete}><DeleteIcon /></Delete>
            </CartActions>
        </Wrapper>
    )
};

const Wrapper = styled.div`
      display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.a`
      margin-right: 10px;
    color: black;
    text-decoration: none;
    font-size: 16px;
    &:hover {
    color: ${props => props.hover_color || '#ff920a'};
    }
  `;

const Delete = styled.div`
  display: flex;
  cursor: pointer;
      align-items: center;
    margin-left: 3px;
    opacity: .4;

    &:hover {
    opacity: 1;
    }
  `;

const CartActions = styled.div`
  display: flex;
  `;

const Icon = styled.div`
  height: 35px;
  overflow: hidden;
    margin-top: -20px;
    margin-left: -15px;
  `;
