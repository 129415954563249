import styled from 'styled-components';

export function MainButton({onClick, children}) {
    return (
        <Button onClick={onClick}>
            {children}
        </Button>
    )
}

const Button = styled.button`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: #93720716;
    border: none;
    color: #937207;
    font-family: 'Cormorant Infant';
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    cursor: pointer;
`;
